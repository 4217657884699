import React from "react";

import styles from "./File.module.scss";

const File = (props) => {
	const {
		className,
		label,
		btnTitle,
		onChange = () => {},
	} = props;

	return (
		<div className={`${styles.container} ${className || ""}`}>
			<div className={styles.label}>
				{label}
			</div>
			<input style={{display: "none"}} type="file" id={label} onChange={onChange}/>
			<label className="btn-square _conflower-blue" htmlFor={label}>
				{btnTitle}
			</label>
		</div>
	);
};

export default File;