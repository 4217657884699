import React, {Component} from "react";

import Sidebar from "components/SideBar/Sidebar";
import Main from "components/Main/Main";
import MainContent from "components/MainContent/MainContent";
import GOTVSidebar from "./GOTVSidebar/GOTVSidebar";
import CreativesList from "components/CreativesList/CreativesList";

import styles from "./GOTV.module.scss";

export default class GOTV extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<Main>
				<Sidebar creative={{title: "GOTV", icon: "gotvXS"}}>
					<GOTVSidebar/>
				</Sidebar>
				<MainContent>
					<CreativesList/>
				</MainContent>
			</Main>
		);
	}
}