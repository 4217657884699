import React, {Component} from "react";

import styles from "./PreviewSite.module.scss";

import mobileImage from "img/preview/mobile.png";
import desktopImage from "img/preview/desktop.png";
import tvImage from "img/preview/tv.png";
import tabletImage from "img/preview/tablet.png";

import {devices} from "components/consts";

const devicesImages = [mobileImage, desktopImage, tvImage, tabletImage];

class PreviewSite extends Component {
	constructor() {
		super();
		this.state = {
			fontSize: 0,
		};
	}

	devices = devices.map((i, k) => ({ type: i.value, image: devicesImages[k] }))

	componentDidMount() {
		window.addEventListener("resize", this.setFontSize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setFontSize);
	}

	getFontSize = () => {
		if(!this.siteWrapper) {
			return;
		}

		const wrapperWidth = this.siteWrapper.offsetWidth;
		const windowWidth = window.innerWidth;

		return wrapperWidth / windowWidth * 100;
	}

	setFontSize = () => this.setState({fontSize: this.getFontSize()})

	update = () => {
		this.setState({stateType: this.props.type}, () => {
			this.setState({fontSize: this.getFontSize()});
		});
	}

	render() {
		const {
			type,
		} = this.props;

		const {
			fontSize,
			stateType,
		} = this.state;

		return (
			<div className={`${styles.component}`} style={{fontSize: `${fontSize}vw`}}>
				<img
					src={this.devices.find(i => i.type === type).image}
					alt={type}
					onLoad={() => this.update()}
				/>
				<div className={`${styles.site} ${styles[`_${stateType}`]}`}>
					<div className={`${styles.wrapper}`} ref={el => this.siteWrapper = el}>
						{stateType !== "mobile" ?
							<header className={styles.header}>
								<div className={styles.container}>
									<div className={styles.logo}></div>
									{[...new Array(5)].map((i, k) => (
										<div className={`${styles.headerItem} ${k === 0 ? "_active" : ""}`}
											 key={k}></div>
									))}
									<div className={styles.search}></div>
									<div className={styles.profileImage}></div>
									<div className={styles.profileName}></div>
								</div>
							</header>
							:
							<header className={styles.header}>
								<div className={styles.container}>
									<div className={styles.logo}></div>
									<div className={styles.title}></div>
									<div className={styles.logo}></div>
									<div className={styles.profileName}></div>
								</div>
							</header>
						}
						<div className={styles.container}>
							<div className={styles.main}>
								<div className={styles.leftSidebar}>
									{[...new Array(5)].map((i, k) => (
										<div className={styles.leftSidebarItem} key={k}></div>
									))}
								</div>

								<div className={styles.content}>
									<div className={styles.mainHeader}>
										{[...new Array(3)].map((i, k) => (
											<div className={styles.mainHeaderItem} key={k}></div>
										))}
									</div>

									<div className={styles.block}>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `4em`}}></div>
											<div className={styles.item} style={{width: `30em`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `55em`}}></div>
										</div>
									</div>

									<div className={styles.block}>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `55em`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `65em`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `4em`}}></div>
											<div className={styles.item} style={{width: `30em`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.image} style={{height: `36em`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `66%`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `80%`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `4em`}}></div>
											<div className={styles.item} style={{width: `4em`}}></div>
											<div className={styles.item} style={{width: `4em`}}></div>
										</div>
									</div>

									<div className={styles.block}>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `55em`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `65em`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.item} style={{width: `4em`}}></div>
											<div className={styles.item} style={{width: `30em`}}></div>
										</div>
										<div className={styles.row}>
											<div className={styles.image} style={{height: `36em`}}></div>
										</div>
									</div>
								</div>

								<div className={styles.rightSidebar}>
									{[...new Array(9)].map((i, k) => (
										<div className={styles.rightSidebarItem} key={k}></div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PreviewSite;