import image1 from "img/example/html/html-1.jpg";
import image2 from "img/example/html/html-2.jpg";
import image3 from "img/example/html/html-3.jpg";

export const textOptions = [
	{label: "Option 1", value: "Option 1"},
	{label: "Option 2", value: "Option 2"},
	{label: "Option 3", value: "Option 3"},
];

export const importCreativesList = [
	{
		title: "Image",
		icon: "BannerXS",
		url: "/image",
	},
	{
		title: "HTML",
		icon: "HtmlXS",
		url: "/html",
	},
	{
		title: "Audio",
		icon: "VoiceXS",
		url: "/audio",
	},
	{
		title: "GOTV",
		icon: "gotvXS",
		url: "/gotv",
	},
	{
		title: "Video",
		icon: "VideoXS",
		url: "/video",
	},
	{
		title: "Ticker",
		icon: "scrollXS",
		url: "/ticker",
	},
];

export const moreActions = [
	{
		icon: "Copy",
		title: "Duplicate",
	},
	{
		icon: "Edit",
		title: "Edit",
	},
	{
		icon: "Delete",
		title: "Delete",
	},
];

export const totalMoreActions = [
	{
		icon: "Copy",
		title: "Duplicate",
	},
	{
		icon: "Edit",
		title: "Rename",
	},
	{
		icon: "Delete",
		title: "Delete",
	},
];

export const buttonsGroupArr = [
	{ title: "Creatives", value: "creatives" },
	{ title: "Groups", value: "groups" },
];

export const creativesList = [
	{
		id: "001",
		checked: false,
		image: image1,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "rejected",
	},
	{
		id: "002",
		checked: false,
		image: image2,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "pending",
	},
	{
		id: "003",
		checked: false,
		image: image3,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "approved",
	},
	{
		id: "004",
		checked: false,
		image: image1,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "rejected",
	},
];

export const creativesList1 = [
	{
		id: "001",
		checked: false,
		title: "Violet Banner",
		creativesNumber: 12,
		creativesStatus: {
			approved: 12,
			pending: 3,
			rejected: 1,
		},
		created: "25 August 2018",
		partOf: "NoCampaign",
		type: "HTML",
		status: "rejected",
	},
	{
		id: "002",
		checked: false,
		title: "Violet Banner",
		creativesNumber: 12,
		creativesStatus: {
			approved: 12,
			pending: 3,
			rejected: 1,
		},
		created: "25 August 2018",
		partOf: "NoCampaign",
		type: "HTML",
		status: "rejected",
	},
	{
		id: "003",
		checked: false,
		title: "Violet Banner",
		creativesNumber: 12,
		creativesStatus: {
			approved: 12,
			pending: 3,
			rejected: 1,
		},
		created: "25 August 2018",
		partOf: "NoCampaign",
		type: "HTML",
		status: "rejected",
	},
	{
		id: "004",
		checked: false,
		title: "Violet Banner",
		creativesNumber: 12,
		creativesStatus: {
			approved: 12,
			pending: 3,
			rejected: 1,
		},
		created: "25 August 2018",
		partOf: "NoCampaign",
		type: "HTML",
		status: "rejected",
	},
];

export const devices = [
	{ title: "Mobile", value: "mobile", },
	{ title: "Desktop", value: "desktop", },
	{ title: "TV", value: "tv", },
	{ title: "Tablet", value: "tablet", },
];