import React, {Component} from "react";

import Sidebar from "components/SideBar/Sidebar";
import Main from "components/Main/Main";
import MainContent from "components/MainContent/MainContent";
import VideoSidebar from "./VideoSidebar/VideoSidebar";
import CreativesList from "components/CreativesList/CreativesList";

import styles from "./Video.module.scss";

export default class Video extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<Main>
				<Sidebar creative={{title: "Video", icon: "VideoXS"}}>
					<VideoSidebar/>
				</Sidebar>
				<MainContent>
					<CreativesList/>
				</MainContent>
			</Main>
		);
	}
}