import React, {Component} from "react";

import Sidebar from "components/SideBar/Sidebar";
import Main from "components/Main/Main";
import HTMLSidebar from "components/HTML/HTMLSidebar/HTMLSidebar";
import MainContent from "components/MainContent/MainContent";
import CreativesList from "components/CreativesList/CreativesList";

import styles from "./HTML.module.scss";

export default class HTML extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<Main>
				<Sidebar creative={{title: "HTML", icon: "HtmlXS"}}>
					<HTMLSidebar/>
				</Sidebar>
				<MainContent>
					<CreativesList/>
				</MainContent>
			</Main>
		);
	}
}