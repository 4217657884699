import React, {Component} from "react";
import {ButtonCircle, Select, ButtonsGroup} from "factor";
import {connect} from "react-redux";

import {changeMode} from "store/actions";

import styles from "./HeaderContent.module.scss";

import {buttonsGroupArr} from "components/consts";

const groupOptions = [
	{ label: "Unassigned creatives", value: "Unassigned creatives" },
	{ label: "March Creative", value: "March Creative" },
	{ label: "April Creative", value: "April Creative" },
	{ label: "June Creative", value: "June Creative" },
];

class HeaderContent extends Component {
	constructor() {
		super();
		this.state = {
			group: groupOptions[0],
		};
	}

	render() {
		const {
			mode,
			changeMode,
		} = this.props;

		const {
			group,
		} = this.state;

		return <React.Fragment>
			<Select
				options={groupOptions}
				onChange={(v) => this.setState({
					group: v
				})}
				value={group}
			/>
			<ButtonsGroup
				size="sm"
				items={buttonsGroupArr}
				value={mode}
				onChange={(v) => changeMode(v)}
			/>
			<div ref={el => this.navMenuWrapper = el}>
				<ButtonCircle
					className="_size-16"
					key="nav-menu-btn"
					iconName="Apps"
					outline={true}
					onClick={this.togglePopup}
				/>
			</div>
		</React.Fragment>;
	}
}

export default connect(
	state => ({
		mode: state.mode,
	}),
	dispatch => changeMode(dispatch)
)(HeaderContent);