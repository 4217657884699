import React, {Component} from "react";

import Sidebar from "components/SideBar/Sidebar";
import Main from "components/Main/Main";
import MainContent from "components/MainContent/MainContent";
import AudioSidebar from "./AudioSidebar/AudioSidebar";

import styles from "./Audio.module.scss";

export default class Audio extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<Main>
				<Sidebar creative={{title: "Audio", icon: "VoiceXS"}}>
					<AudioSidebar/>
				</Sidebar>
				<MainContent>

				</MainContent>
			</Main>
		);
	}
}