import React from "react";
import {connect} from "react-redux";

import {setDialogVisibility} from "store/actions";

import Preview from "components/Preview/Preview";

import styles from "./Main.module.scss";

const Main = props => (
	<div className={`${styles.main} ${(props.activeSidebar && !props.noSidebar) ? "_opened" : ""} ${props.dialogOpened ? "_dialog-opened" : ""}`}>
		{props.children}

		<Preview open={props.dialogOpened} onClickOutside={() => props.setDialogVisibility(false)}/>
	</div>
);

export default connect(
	state => ({
		dialogOpened: state.dialogOpened,
		activeSidebar: state.activeSidebar,
	}),
	dispatch => (setDialogVisibility(dispatch))
)(Main);