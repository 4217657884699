import React, {Component} from "react";

import Sidebar from "components/SideBar/Sidebar";
import Main from "components/Main/Main";
import MainContent from "components/MainContent/MainContent";
import ImageSidebar from "./ImageSidebar/ImageSidebar";

import styles from "./Image.module.scss";

export default class Image extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<Main>
				<Sidebar creative={{title: "Image", icon: "BannerXS"}}>
					<ImageSidebar/>
				</Sidebar>
				<MainContent>

				</MainContent>
			</Main>
		);
	}
}