import React from "react";

import Routing from "components/Routing/Routing";
import Header from "components/Header/Header";

function App() {
  return (
      <div className="page">
          <Header/>
          <Routing/>
      </div>
  );
}

export default App;
