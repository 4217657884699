import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Mainpage from "components/Mainpage/Mainpage";
import HTML from "components/HTML/HTML";
import Image from "components/Image/Image";
import Audio from "components/Audio/Audio";
import GOTV from "components/GOTV/GOTV";
import Video from "components/Video/Video";
import Ticker from "components/Ticker/Ticker";

function Routing() {
  return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Mainpage}/>
          <Route exact path="/html" component={HTML}/>
          <Route exact path="/image" component={Image}/>
          <Route exact path="/audio" component={Audio}/>
          <Route exact path="/gotv" component={GOTV}/>
          <Route exact path="/video" component={Video}/>
          <Route exact path="/ticker" component={Ticker}/>
        </Switch>
      </BrowserRouter>
  );
}

export default Routing;
