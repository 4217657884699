import React from "react";

import Sidebar from "components/SideBar/Sidebar";
import ImportCreatives from "components/ImportCreatives/ImportCreatives";
import Main from "components/Main/Main";
import MainContent from "components/MainContent/MainContent";

import styles from "./Mainpage.module.scss";

const Mainpage = () => (
	<Main>
		<Sidebar className="_mainpage">
			<ImportCreatives/>
		</Sidebar>
		<MainContent>
			<div className={styles.empty}>No Unassigned Creative <span>(Drag and Drop Creatives)</span></div>
		</MainContent>
	</Main>
);

export default Mainpage;