import React, {Component} from "react";
import {
	TextField,
	DatesPicker,
	ButtonsGroup,
	Checkbox,
	Tooltip,
	Icon,
} from "factor";

import File from "components/File/File";

import styles from "./GOTVSidebar.module.scss";

const devices = [
	{ title: "iOS", value: "iOS" },
	{ title: "Android", value: "Android" },
	{ title: "Desktop", value: "Desktop" },
];

class GOTVSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clickUrl: "",
			dateRange: new Date(),
			device: devices[0].value,
			blocksCheckboxes: [
				{title: "320 x 50", checked: true},
				{title: "300 x 250", checked: false},
			],
		}
	}

	render() {
		const {
			clickUrl,
			dateRange,
			device,
			blocksCheckboxes,
		} = this.state;

		return (
			<div className={styles.container}>
				<div className={styles.row}>
					<TextField
						label="Click URL (Optional)"
						value={clickUrl}
						onChange={(v) => this.setState({clickUrl: v})}
					/>
				</div>
				<div className={`row ${styles.row}`}>
					<div className="col-6">
						<div className={styles.label}>Date (Optional)</div>
						<DatesPicker
							updateDateRange={(value) => this.setState({
								dateRange: new Date(value),
							})}
							singleDateMode={true}
							dateFormat="DD/MM/YYYY"
							singleDate={dateRange}
							datePickerProps={{
								numberOfCalendars: 1,
								minimumDate: new Date(),
							}}
							labels={['Date (Optional)']}
						/>
					</div>
					<div className="col-6">
						<File
							label={<span className="d-block mb-2">* Voter & Location</span>}
							btnTitle="Upload CSV"
						/>
					</div>
				</div>
				<div className={`row justify-content-center ${styles.row}`}>
					<ButtonsGroup
						items={devices}
						value={device}
						onChange={(v) => this.setState({device: v})}
					/>
				</div>
				{blocksCheckboxes.map((i, k) => (
					<div className={styles.block} key={i.title}>
						<div className={styles.blockHeader}>
							<Checkbox
								checked={i.checked}
								onChange={() => {
									i.checked = !i.checked;
									this.setState({blocksCheckboxes: blocksCheckboxes});
								}}
							/>
							<div className={styles.blockSize}>
								{i.title}
							</div>
							<Tooltip label={i.title}>
								<Icon name="Info"/>
							</Tooltip>
						</div>
						{i.checked &&
							<div className={styles.blockContent}>
								<File className={styles.blockUpload} btnTitle={`Upload 200 x 50`}/>
								<div className={styles.blockList}>
									<div className={styles.blockItem}>
										<div className={styles.blockBtn}>
											<span>
												Trump2020.jpg
											</span>
											<button className={`btn-close ${styles.blockItemRemove}`}></button>
										</div>
										<Icon name="Impressions"/>
									</div>
									<div className={styles.blockItem}>
										<div className={styles.blockBtn}>
											<span>
												AmericaGreat.png
											</span>
											<button className={`btn-close ${styles.blockItemRemove}`}></button>
										</div>
										<Icon name="Impressions"/>
									</div>
								</div>
							</div>
						}
					</div>
				))}
			</div>
		);
	}
}

export default GOTVSidebar;