import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Icon, Button} from "factor";
import {connect} from "react-redux";

import SidebarHeader from "./SidebarHeader/SidebarHeader";
import CreativesList from "components/CreativesList/CreativesList";

import styles from "./Sidebar.module.scss";

import {toggleSidebar} from "store/actions";

import {ReactComponent as ArrowLeftIcon} from "img/arrow-left.svg";

class Sidebar extends Component {

	componentDidMount() {
		if(!this.props.notFixed) {
			window.addEventListener("resize", this.toggleSidebarOnResize);
			this.toggleSidebarOnResize();
		}
	}

	componentWillUnmount() {
		if(!this.props.notFixed) {
			window.removeEventListener("resize", this.toggleSidebarOnResize);
		}
	}

	toggleSidebarOnResize = () => {
		if (this.windowWidth === undefined) {
			this.windowWidth = window.innerWidth;

			if (this.windowWidth < 1280) {
				this.toggleSidebar();
			} else {
				return;
			}
		} else {
			if (this.windowWidth < 1280) {
				this.windowWidth = window.innerWidth;
				return;
			} else {
				if (window.innerWidth < 1280) {
					this.windowWidth = window.innerWidth;
					this.toggleSidebar();
				}
			}
		}
	}

	toggleSidebar = (show) => {
		const {
			toggleSidebar,
			activeSidebar,
		} = this.props;

		toggleSidebar(!activeSidebar);
	}

	renderHeader = () => {
		const {
			mode,
			creative,
			header,
		} = this.props;

		return (
			mode === "groups" ?
				<header className={styles.header}>
					<h3 className={styles.title}>
						Creative List
					</h3>
				</header>
				:
				creative ?
					<div className={styles.creative}>
						<i className={styles.creativeIcon}>
							<Icon name={creative.icon}/>
						</i>
						<span>
							{creative.title}
						</span>
						<Link to="/" className={`btn-close ${styles.creativeClose}`}/>
					</div>
					:
					<header className={styles.header}>
						<h3 className={styles.title}>
							Select Creative Type
						</h3>
						{header}
					</header>
		);
	}

	renderContent = () => {
		const {
			mode,
			children,
			creative,
		} = this.props;

		const withPreview = creative && ["Audio", "Image"].indexOf(creative.title) === -1;

		if(mode === "groups") {
			return (
				<div className={styles.content}>
					<CreativesList
						withControls={false}
						view="tile"
						column={true}
					/>
				</div>
			);
		} else if(creative) {
			return (
				<div className={styles.content}>
					<SidebarHeader withPreview={withPreview}/>
					{children}
					<div className="d-flex justify-content-end">
						<Button className="btn-round _conflower-blue mr-4">
							Cancel
						</Button>
						<Button className="btn-round _filled _conflower-blue">
							Add
						</Button>
					</div>
				</div>
			)
		} else {
			return children;
		}
	}

	render() {
		const {
			className,
			activeSidebar,
			toggleSidebar,
		} = this.props;

		let containerClassNames = [styles.sidebar];

		if(!activeSidebar) {
			containerClassNames.push("_hidden");
		}
		if(className) {
			containerClassNames.push(className);
		}

		return (
			<div className={`${containerClassNames.join(" ")}`}>
				<button
					className={styles.arrow}
					onClick={() => toggleSidebar(!activeSidebar)}
				>
					<ArrowLeftIcon/>
				</button>

				<div className={styles.container}>
					{this.renderHeader()}
					<div className={styles.main}>
						{this.renderContent()}
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	state => ({
		activeSidebar: state.activeSidebar,
		mode: state.mode,
	}),
	dispatch => toggleSidebar(dispatch),
)(Sidebar);