import React, {Component} from "react";
import {Dialog, DialogContent, Tabs, Icon} from "factor";

import styles from "./Preview.module.scss";

import PreviewSite from "./PreviewSite/PreviewSite";

import { devices } from "components/consts";

class Preview extends Component {
	constructor() {
		super();
		this.state = {
			tab: devices[0].value,
		};
	}

	render() {
		const {
			open,
			onClickOutside,
		} = this.props;

		const {
			tab,
		} = this.state;

		return (
			<Dialog className={styles.dialog} open={!!open} onClickOutside={onClickOutside}>
				<DialogContent>
					<div className={styles.container}>
						<header className={styles.header}>
							<i className={styles.icon}>
								<Icon name="HtmlXS"/>
							</i>
							<h4 className={styles.title}>
								Violet Banner
							</h4>
							<div className={styles.size}>
								Size: 250 x 250
							</div>
							<button className={`btn-close ${styles.close}`} onClick={onClickOutside}></button>
						</header>
						<div className={styles.tabs}>
							<Tabs
								items={devices}
								onChange={(item) => this.setState({tab: item.value})}
								value={tab}
							/>
						</div>
						<div className={styles.image}>
							<PreviewSite type={tab}/>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	}
}

export default Preview;