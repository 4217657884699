import {buttonsGroupArr} from "components/consts";

let initialState = {
	mode: buttonsGroupArr[0].value,
	activeSidebar: true,
};

function reducer(state = initialState, action) {
	switch(action.type) {
		case "DIALOG_TOGGLE":
			return {
				...state,
				dialogOpened: action.payload,
			};
		case "MODE_CHANGE":
			return {
				...state,
				mode: action.payload,
			};
		case "SIDEBAR_TOGGLE":
			return {
				...state,
				activeSidebar: action.payload,
			};
		default:
			return state;
	}
}

export default reducer;