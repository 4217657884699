import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Icon} from "factor";

import styles from "./ImportCreatives.module.scss";

import {importCreativesList} from "components/consts";

export default class ImportCreatives extends Component {
	render() {
		return (
			<div className={styles.container}>
				<div className={styles.list}>
					{importCreativesList.map(i => (
						<div className={`${styles.block}`} key={i.title}>
							<Link to={i.url} className={`block ${styles.item}`}>
								<div className={styles.itemIcon}>
									<Icon name={i.icon}/>
								</div>
								<h4 className={styles.itemTitle}>
									{i.title}
								</h4>
							</Link>
						</div>
					))}
				</div>
			</div>
		);
	}
}