import React, {Component} from "react";
import {
	NavigationBar,
	Dialog,
	DialogContent,
	TextField,
	Button,
} from "factor";

import HeaderContent from "./HeaderContent/HeaderContent";
import {ReactComponent as Logo} from "img/logo.svg";

import "./Header.scss";

class Header extends Component {
	constructor() {
		super();
		this.navigationBar = React.createRef();
		this.state = {
			showNewGroupDialog: false,
			newGroupName: "",
			showDeleteGroupDialog: false,
		}
	}

	toggleModal = (modal, show) => {
		this.setState({[modal]: show});
	}

	render() {
		const {
			showNewGroupDialog,
			newGroupName,
			showDeleteGroupDialog,
		} = this.state;

		const popupContent = <span>popup content</span>;

		return (
			<NavigationBar
				backButton={false}
				ref={this.navigationBar}
				leftLogo={<div className="nav-bar__circle-icon" key="logo"><Logo/></div>}
				title="Creatives"
				popupContent={popupContent}
			>
				<React.Fragment>
					<HeaderContent
						newGroup={() => this.toggleModal("showNewGroupDialog", true)}
						deleteGroup={() => this.toggleModal("showDeleteGroupDialog", true)}
					/>

					<Dialog className="_new-group" open={showNewGroupDialog} onClickOutside={() => this.toggleModal("showNewGroupDialog", false)}>
						<DialogContent>
							<div className="dialog__title">
								New Group
								<button className="btn-close" onClick={() => this.toggleModal("showNewGroupDialog")}></button>
							</div>
							<div className="dialog__input">
								<TextField
									name="new-group-name"
									label="New Group Name"
									onChange={( v ) => this.setState({
										newGroupName: v,
									})}
									value={newGroupName}
								/>
							</div>
							<div className="dialog__buttons _jc-fe">
								<Button className="btn-square _conflower-blue mr-2" onClick={() => this.toggleModal("showNewGroupDialog")}>
									Cancel
								</Button>
								<Button className="btn-square _conflower-blue _filled" onClick={() => this.toggleModal("showNewGroupDialog")}>
									Apply
								</Button>
							</div>
						</DialogContent>
					</Dialog>

					<Dialog className="_new-group _delete-group" open={showDeleteGroupDialog} onClickOutside={() => this.toggleModal("showDeleteGroupDialog", false)}>
						<DialogContent>
							<div className="dialog__p ta-c">
								Do you really want to delete this group?
							</div>
							<div className="dialog__buttons _jc-c">
								<Button className="btn-square _conflower-blue mr-2" onClick={() => this.toggleModal("showDeleteGroupDialog")}>
									Cancel
								</Button>
								<Button className="btn-square _conflower-blue _filled" onClick={() => this.toggleModal("showDeleteGroupDialog")}>
									Delete
								</Button>
							</div>
						</DialogContent>
					</Dialog>
				</React.Fragment>
			</NavigationBar>
		)
	}
}

export default Header;