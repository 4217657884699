import React, {Component} from "react";
import {Button, TextFieldWithIcon, ButtonCircle} from "factor";

import {ReactComponent as ArrowDownIcon} from "img/arrow-down.svg";

import styles from "./ContentControls.module.scss";

export default class ContentControls extends Component {
	constructor() {
		super();
		this.state = {
			search: "",
		}
	}

	render() {
		const {
			view,
			changeView,
		} = this.props;

		const {
			search,
		} = this.state;

		return (
			<div className={styles.container}>
				<div className={styles.selected}>
					<button className={`btn-close ${styles.remove}`}></button>
					1 Creative Selected
				</div>
				<Button className="btn-round _persimmon">
					Delete
				</Button>
				<Button className="btn-round _conflower-blue">
					Duplicate
				</Button>
				<Button className={`btn-round _conflower-blue ${styles.manageBtn}`}>
					Manage Groups
					<ArrowDownIcon/>
				</Button>
				<div className={styles.search}>
					<TextFieldWithIcon
						placeholder="Search"
						iconName="Search"
						value={search}
						onChange={({target}) => this.setState({search: target.value})}
					/>
				</div>
				<ButtonCircle
					outline
					iconName={view === "list" ? "Table" : "List"}
					className={`_size-18 ${styles.tableBtn}`}
					onClick={changeView}
				/>
			</div>
		)
	}
}