export const setDialogVisibility = (dispatch) => ({
	setDialogVisibility: (open) => {
		dispatch({type: "DIALOG_TOGGLE", payload: open});
	}
});

export const changeMode = (dispatch) => ({
	changeMode: (mode) => {
		dispatch({type: "MODE_CHANGE", payload: mode});
	}
});

export const toggleSidebar = (dispatch) => ({
	toggleSidebar: (show) => {
		dispatch({type: "SIDEBAR_TOGGLE", payload: show})
	}
});