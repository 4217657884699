import React from "react";
import {connect} from "react-redux";

import CreativesList from "components/CreativesList/CreativesList";

import {creativesList1} from "components/consts";

const MainContent = (props) => {
	const {
		mode,
		children,
	} = props;

	return (
		<section className="MainContent">
			{mode === "groups" ?
				<CreativesList
					list={creativesList1}
					isTotal={true}
				/>
				:
				children
			}
		</section>
	);
};

export default connect(
	state => ({
		mode: state.mode,
	})
)(MainContent);