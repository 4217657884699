import React, {Component} from "react";
import {
	TextField,
	Select,
} from "factor";

import Textarea from "components/Textarea/Textarea";
import File from "components/File/File";

import styles from "./HTMLSidebar.module.scss";

import {textOptions} from "components/consts";

class HTMLSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			HTMLCode: "",
			height: "",
			width: "",
			clickUrl: "",
			creativeFramework: null,
		}
	}

	render() {
		const {
			HTMLCode,
			height,
			width,
			clickUrl,
			creativeFramework,
		} = this.state;

		return (
			<div className={styles.container}>
				<div className={styles.row}>
					<Textarea
						className={styles.textarea}
						label="* HTML Code"
						placeholder=""
						value={HTMLCode}
						onChange={(v) => this.setState({HTMLCode: v})}
					/>
				</div>
				<div className={`row ${styles.row}`}>
					<div className="col-6">
						<TextField
							label="* Height"
							value={height}
							onChange={(v) => this.setState({height: v})}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="* Width"
							value={width}
							onChange={(v) => this.setState({width: v})}
						/>
					</div>
				</div>
				<div className={`row ${styles.row}`}>
					<div className="col-6">
						<File
							label="* Attach Screenshot"
							btnTitle="Browse"
						/>
					</div>
				</div>
				<div className={styles.row}>
					<TextField
						label="Click URL (Optional)"
						value={clickUrl}
						onChange={(v) => this.setState({clickUrl: v})}
					/>
				</div>
				<div className={styles.row}>
					<Select
						className={styles.select}
						label="Creative Framework"
						showControlLabel
						placeholder="Select"
						value={creativeFramework}
						options={textOptions}
						onChange={(v) => this.setState({creativeFramework: v})}
					/>
				</div>
			</div>
		);
	}
}


export default HTMLSidebar;