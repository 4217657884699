import React, {Component} from "react";
import {
	Select,
	ButtonsGroup,
} from "factor";

import Textarea from "components/Textarea/Textarea";

import styles from "./VideoSidebar.module.scss";

import {textOptions} from "components/consts";

const xmlOrVideoArr = [
	{title: "VAST XML", value: "VAST XML"},
	{title: "Upload Video", value: "Upload Video"},
];

class VideoSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vastType: null,
			vastVersion: null,
			adPlacement: null,
			xmlOrVideo: xmlOrVideoArr[0].value,
			vastXml: "",
		}
	}

	render() {
		const {
			vastType,
			vastVersion,
			adPlacement,
			xmlOrVideo,
			vastXml,
		} = this.state;

		return (
			<div className={styles.container}>
				<div className={styles.row}>
					<Select
						className={styles.select}
						label="* VAST Type"
						showControlLabel
						placeholder="Select"
						value={vastType}
						options={textOptions}
						onChange={(v) => this.setState({vastType: v})}
					/>
				</div>
				<div className={styles.row}>
					<Select
						className={styles.select}
						label="* VAST Version"
						showControlLabel
						placeholder="Select"
						value={vastVersion}
						options={textOptions}
						onChange={(v) => this.setState({vastVersion: v})}
					/>
				</div>
				<div className={styles.row}>
					<Select
						className={styles.select}
						label="* Ad Placement"
						showControlLabel
						placeholder="Select"
						value={adPlacement}
						options={textOptions}
						onChange={(v) => this.setState({adPlacement: v})}
					/>
				</div>
				<div className={`row justify-content-center ${styles.row}`}>
					<ButtonsGroup
						items={xmlOrVideoArr}
						value={xmlOrVideo}
						onChange={(v) => this.setState({xmlOrVideo: v})}
					/>
				</div>
				<div className={styles.row}>
					<Textarea
						className={styles.textarea}
						label="* VAST XML"
						placeholder=""
						value={vastXml}
						onChange={(v) => this.setState({vastXml: v})}
					/>
				</div>
			</div>
		);
	}
}

export default VideoSidebar;